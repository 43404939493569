@import url("https://fonts.cdnfonts.com/css/helvetica-neue-9");

body{
  font-family: "Helvetica 45 Light", sans-serif;
}

.main_container {
  width: 100%;
  height: 100vh;
  display: flex;  
  flex-direction: row;
  background-color: #F4F6FA;
}

.signLeft_container {
  width: 42%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signIn_margin {
  margin-top: 200px;
  height: calc(100vh - 200px);
}

.signLeft_container h2 {
  font-family: 'CreamCake';
  color: #3A7CFF;
  font-size: 96px;
  font-weight: 100;
  margin-top: 0px;
  margin-bottom: 5px;
}

.signLeft_container p {
  margin-bottom: 20px;
  font-family: "Helvetica 45 Light", sans-serif;
}

.input_container {
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-top: 10px;
  width: 70%;
}

.loginButton_container {
  margin-top: 20px;
}

.signUpButton_container {
  margin-top: 35px;
  width: 100%;
  display: flex;
}

.signUp_setting {
  width: 100%;
}

.signIn_button {
  background: #3B7BFF;
  border-radius: 5px;
  padding: 13px 40px;
  border: none;
  color: white;
  font-size: 18px;
  font-family: "Helvetica 45 Light", sans-serif;
  cursor: pointer;
}

.back_container {
  width: 70%;
  display: flex;
  justify-content: left;
  margin-top: 80px;
}

.trendy_forgotPassword {
  margin-bottom: 150px;
}

.back_container img {
  width: 23px;
  height: 25px;
  cursor: pointer;
}

.signUp_button {
  color: #3B7BFF;
  border-radius: 5px;
  padding: 13px 40px;
  border: #3B7BFF 1px solid;
  background: white;
  font-family: "Helvetica 45 Light", sans-serif;
  font-size: 18px;
  cursor: pointer;
  margin-left: 30px;
}

.forgot_password {
  font-size:16px;
  text-align: left;
  letter-spacing: 0px;
  color: #AAC6FF;
  margin-top: 20px;
  font-family: "Helvetica 45 Light", sans-serif;
  cursor: pointer;
}

.signRight_container {
  width: 58%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signRight_container img {
  width: 75%;
  height: 52vh;
}

.dashboard_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: #F4F6FA;
}

.dashboardLeft_container {
  width: 15.5%;
  height: 100vh;
  background: #ffffff;
  box-shadow: 4px 0px 5px #0000000A;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dashboardLeft_container h2 {
  font-family: 'CreamCake';
  color: #3A7CFF;
  font-size: 56px;
  font-weight: 100;
  margin-top: 60px;
  margin-bottom: 25px;
}

.dashboardButton_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #F4F6FA;
  width: 80%;
  margin-top: 15px;
  cursor: pointer;
  text-decoration: none;
}

.dashboardButton_container img {
  width: 24px;
  height: 24px;
}

.dashboardButton_container p {
  font-size: 18px;
  margin-left: 10px;
  color: #9B9B9B;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonLeft_cross {
  width: 6px;
  height: 100%;
  background: #AAC6FF;
  margin-right: 25px;
}

.buttonLeftM_cross {
  width: 6px;
  height: 100%;
  background: none;
  margin-right: 25px;
}

.dashboardRight_container {
  display:  flex;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 40px;
  width: calc(100% - 19.5%);
  padding-right: 110px;
  align-items: flex-end;
}

.dashboardName_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.dashboardName_container h4 {
  font-size: 26px;
  margin-top: 0px;
  margin-bottom: 0;
  font-weight: bold;
}

.dashboard_avatar {
  width: 52px;
  height: 52px;
  background: #ffffff;
  border-radius: 1000px;
  margin-left: 20px;
}

.dashboardChart_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboardChart_container input {
  width: calc(1005 - 10px);
  height: 60px;
  background: white;
  color: black;
  font-size: 16px;
  padding-left: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
}

.dashboardChart_container select {
  width: calc(1005 - 10px);
  height: 60px;
  background: white;
  color: black;
  font-size: 16px;
  padding-left: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
}

.dashboardChart_container button {
  width: 180px;
  background-color: #3A7CFF;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  padding: 13px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dashboardChart_container button img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.dashboardChart_container input:focus {
  outline: none;
}

.dashboardChart_container h4 {
  font-size: 26px;
  font-weight: bold;
}

.chart_title {
  display: flex;
  flex-direction: row;
}

.chart_title h5 {
  color: #9B9B9B;
  font-size: 19px;
  margin-top: 0;
  margin-left: 25px;
  margin-right: 75px;
}

.dashboardchart_main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 71vh;
  overflow: auto;
}

  .dashboardchart_main::-webkit-scrollbar-track {
    background-color: #bebdbe;
    width: 4px;
  }

  .dashboardchart_main::-webkit-scrollbar {
    width: 4px;
    background-color: #c5c5c5;
  }

  .dashboardchart_main::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #f1efea 0%, #747372 100%);
    border-radius: 10px;
  }

.chartInfo_main {
  display: flex;
  flex-direction: row;
  background: white;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  align-items: center;
}

.chartInfo_mainName{
  font-size: 17px;
  font-weight: bold;
  margin-left: 30px;
  width: 220px;
}

.chartInfo_mainWebsite {
  font-size: 17px;
  font-weight: bold;
  width: 175px;
  /* background: #9B9B9B; */
}

.chartInfo_mainUrl {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chartInfo_mainUrl img {
  width: 23px;
  height: 23px;
  margin-left: 40px;
}

.mainUrl_detail {
  font-size: 17px;
  font-weight: bold;
  width: 830px;
  overflow: hidden;
}

.hamburger__menu {
  width: 25px;
  height: 25px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 10px;
}

.hamburger__menu > div {
  width: 100%;
  height: 3px;
  background: black;
}

.form__group {
  font-family: "Helvetica Neue", sans-serif;
  position: relative;
  width: 100%;
  height: 90px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: -1px;
}

.form__group .form__label {
  font-family: "Helvetica 45 Light", sans-serif;
  position: absolute;
  left: 36px;
  top: 15px;
  height: 23px;
  font-size: 18px;
  color: #9b9b9b;
}

.form__group .form__field {
  font-family: inherit;
  position: absolute;
  left: 36px;
  bottom: 15px;
  height: 28px;
  font-size: 22px;
  outline: 0;
  font-weight: bold;
  background: none;
  border: none;
  width: calc(100% - 120px);
}

.form__group:has(input:focus)::before {
  content: "";
  width: 5px;
  height: calc(100%);
  position: absolute;
  border-left: 5px solid #aac6ff;
}

.form__group .form__passwordicon{
  transition: all 0.2s;
  position: absolute;
  right: 36px;
  border: none;
  background: none;
  top: calc(50% - 18px);
  fill: gray;
  width: 36px;
  height: 36px;
}

.form__group .form__passwordbutton {
  border: none;
  background: none;
}

.form__group .form__passwordbutton:hover .form__passwordicon {
  fill: black;
}

.form__group .PhoneInputInput{
  height: 100%;
  border: none;
  background: none;
  font-size: 24px;
  outline: 0;
  font-weight: bold;
  padding-left: 10px;
  border-left: 2px solid #9b9b9b;
}

.nameTitle_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.nameTitle_container img {
  width: 30px;
  height: 30px;
}

.left__container__buttons__menu {
  width: 100%;
  display: none;
}

/* SignUp CSS START */

.modal_background {
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 60;
  position: fixed;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.12);
}

.dashboard_mainContainer {
  width: 100%;
}

.signUp_container {
  display: flex;
  /* padding: 25px 25px; */
  width: 650px;
  height: 640px;
  background: #F4F6FA;
  box-shadow: 0px 4px 49px rgba(0, 7, 72, 0.12);
  backdrop-filter: blur(12.5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  position: absolute;
  top: 10%;
  right: 30%;
  z-index: 70;
}

.signUpContainer_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px;
  position: relative;
}

.backImage_container {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.signUp_main {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
  justify-content: center;
}

.dashboard_userName {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}


/* SignUp CSS END */


@media (max-width: 1850px) {
  .dashboardRight_container {
    padding-left: 30px;
    padding-right: 35px;
  }

  .chart_title h5 {
    margin-right: 43px;
  }

  .chartInfo_mainName {
    width: 187px;
  }

  .chartInfo_mainWebsite {
    width: 150px;
  }
}

@media (max-width: 1660px) {
  .dashboardLeft_container {
    width: 20.5%;
  }

  .dashboardRight_container {
    padding-left: 25px;
    padding-right: 40px;
    width: calc(100% - 20.5%);
  }

  .dashboardName_container h4 {
    font-size: 24px;
  }

  .dashboard_avatar {
    width: 44px;
    height: 44px;
  }

  .dashboardChart_container h4 {
    font-size: 24px;
  }

  .chart_title h5 {
    font-size: 16px;
    margin-left: 15px;
    margin-right: 55px;
  }

  .chartInfo_mainName {
    font-size: 12px;
    width: 165px;
  }

  .chartInfo_mainWebsite {
    font-size: 12px;
    width: 135px;
  }

  .mainUrl_detail {
    font-size: 12px;
    width: 630px;
  }

  .chartInfo_mainUrl img {
    margin-left: 30px;
  }
}

@media (max-width: 550px) {
  .dashboard_userName {
    margin-right: 20px;
  }
  .main_container {
    flex-direction: column-reverse;
    height: 100%;
  }

  .signIn_margin {
    margin-top: 30px;
    height: 100%;
  }

  .signLeft_container {
    width: 100%;
    height: 100%;
    margin-bottom: 100px;
  }

  .signLeft_container h2 {
    font-size: 85px;
  }

  .signLeft_container p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .input_container {
    width: 85%;
  }

  .form__group {
    height: 75px;
  }

  .form__group .form__field {
    left: 20px;
    bottom: 5px;
    height: 28px;
    font-size: 18px;
    width: calc(100% - 70px);
  }

  .form__group .form__label {
    left: 20px;
    top: 13px;
    font-size: 18px;
  }

  .form__group .form__passwordicon {
    top: calc(50% - 0px);
    width: 26px;
    height: 26px;
    right: 16px;
  }

  .forgot_password {
    font-size: 16px;
    margin-top: 20px;
  }

  .signIn_button {
    padding: 13px 35px;
    font-size: 14px;
  }

  .signUp_button {
    font-size: 14px;
    padding: 13px 35px;
  }

  .signRight_container {
    width: 100%;
    height: 100%;
  }

  .signRight_container img {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 85%;
    height: 100%;
  }

  .back_container {
    display: none;
  }

  .signUpButton_container {
    margin-top: 15px;
  }

  .dashboardLeft_container {
    display: none;
  }

  .dashboardRight_container {
    padding-left: 15px;
    width: calc(100% - 25px);
    padding-right: 10px;
    align-items: flex-start;
  }

  .dashboardName_container {
    justify-content: space-between;
  }

  .dashboardName_container h4 {
    font-size: 20px;
  }

  .dashboard_avatar {
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }

  .dashboardChart_container {
    width: 100%;
    overflow: auto;
  }

  /* .dashboardChart_container::-webkit-scrollbar-track {
    background-color: #bebdbe;
    width: 2px;
  }

  .dashboardChart_container::-webkit-scrollbar {
    width: 2px;
    background-color: #c5c5c5;
  }

  .dashboardChart_container::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #f1efea 0%, #747372 100%);
    border-radius: 10px;
  } */

  .dashboardChart_container h4 {
    font-size: 22px;
  }

  .chart_title h5 {
    font-size: 14px;
    margin-left: 20px;
    margin-right: 25px;
  }

  .dashboardchart_main {
    width: 980px;
  }

  .chartInfo_mainName {
    font-size: 12px;
    margin-left: 20px;
    width: 135px;
  }

  .chartInfo_mainWebsite {
    font-size: 12px;
    width: 100px;
  }

  .mainUrl_detail {
    font-size: 12px;
    width: 600px;
  }

  .chartInfo_mainUrl img {
    width: 20px;
    height: 20px;
    margin-left: 25px;
  }

  .hamburger__menu {
    display: flex;
  }


  .backdrop {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
  }

  .backdrop.showmenu {
    display: none;
  }

  .sidedrawer {
    width: 70%;
    height: 100vh;
    display: flex;
    background:  white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    transform: translateX(-100%);
    transition: all 0.3s ease-out;
  }

  .sidedrawer.showmenu {
    transform: translateX(0);
  }
  
  .main__left__container__menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
  }

  .left__container__buttons__menu {
    width: 100%;
    display: block;
  }

  .dashboardLeft_container_ham {
    width: 100%;
    height: 100vh;
    background: #ffffff;
    box-shadow: 4px 0px 5px #0000000A;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .dashboardLeft_container_ham h2 {
    font-family: 'CreamCake';
    color: #3A7CFF;
    font-size: 56px;
    font-weight: 100;
    margin-top: 60px;
    margin-bottom: 25px;
  }

  .signUp_container {
    width: 99.5%;
    right: 0;
  }
}